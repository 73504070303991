import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { VariantType } from 'notistack'

import { NotificationState } from 'interfaces'

const initialState: NotificationState[] = []

export const randomId = (): string => (new Date().getTime() + Math.random()).toString()

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    pushNotification: (
      state,
      action: PayloadAction<AxiosError | { title: string; message: string; variant: VariantType }>
    ) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      const { payload } = action
      const key = randomId()
      if ('isAxiosError' in payload) {
        state.push({
          key,
          title: payload.message,
          message: payload.stack || payload.name,
          options: {
            autoHideDuration: 6000,
            variant: 'error'
          }
        })
      } else {
        let duration: null | number = 10000
        switch (payload.variant) {
          case 'success':
            duration = 5000
            break
          case 'warning':
            duration = 40000
            break
          case 'error':
            duration = null
            break
        }
        state.push({
          key,
          title: payload.title,
          message: payload.message,
          options: {
            autoHideDuration: duration,
            variant: payload.variant
          }
        })
      }
    },
    removeNotification: (state, action: PayloadAction<string | number>) => {
      const index = state.findIndex((n) => n.key === action.payload)
      return [...state.slice(0, index), ...state.slice(index + 1)]
    }
  }
})

// Action creators are generated for each case reducer function
export const { pushNotification, removeNotification } = notificationsSlice.actions

export default notificationsSlice.reducer
