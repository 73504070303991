import { FC } from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

import { Checkin } from 'interfaces'

const Map: FC<{ checkin?: Checkin }> = ({ checkin }) => {
  return (
    <MapContainer
      center={[-38.95133826780207, -68.05917120984229]}
      zoom={14}
      scrollWheelZoom={true}
      style={{ height: '100%' }}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      {checkin?.inLatitude && checkin.inLongitude ? (
        <Marker position={[checkin.inLatitude, checkin.inLongitude]}>
          <Popup>
            Ingreso. <br /> {checkin.in}.
          </Popup>
        </Marker>
      ) : null}
      {checkin?.outLatitude && checkin.outLongitude ? (
        <Marker position={[checkin.outLatitude, checkin.outLongitude]}>
          <Popup>
            Egreso. <br /> {checkin.out}.
          </Popup>
        </Marker>
      ) : null}
    </MapContainer>
  )
}

export default Map
