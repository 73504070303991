import React, { useState, useEffect, createContext } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import esLocale from 'date-fns/locale/es'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'

import CommonLayout from 'layouts/CommonLayout'
import SignUp from 'pages/SignUp'
import SignIn from 'pages/SignIn'
import Home from 'pages/Home/Home'
// import Budgets from 'pages/Budget/Budgets'
// import BudgetsDashboard from 'pages/BudgetsDashboard'
import Checkins from 'pages/Checkin/Checkins'
import Notifications from 'Notifications'

import { getCurrentUser } from 'lib/api/auth'
import { User } from 'interfaces/index'
import { grantPermission, ViewRoute } from 'utils/permission'

// Variables and functions handled globally
export const AuthContext = createContext(
  {} as {
    loading: boolean
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
    isSignedIn: boolean
    setIsSignedIn: React.Dispatch<React.SetStateAction<boolean>>
    currentUser: User | undefined
    setCurrentUser: React.Dispatch<React.SetStateAction<User | undefined>>
  }
)

const localeMap = {
  es: esLocale
}

// const maskMap = {
//   en: '__/__/____',
//   es: '__/__/____',
//   fr: '__/__/____',
//   ru: '__.__.____',
//   de: '__.__.____'
// }

const App: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [isSignedIn, setIsSignedIn] = useState<boolean>(false)
  const [currentUser, setCurrentUser] = useState<User | undefined>()

  // Check if there is an authenticated user.
  // If confirmed, get the user's information
  const handleGetCurrentUser = async () => {
    try {
      const res = await getCurrentUser()

      if (res?.data.success) {
        setIsSignedIn(true)
        setCurrentUser(res?.data.data)

        console.log(res?.data)
      } else {
        console.log('No current user')
      }
    } catch (err) {
      console.log(err)
    }

    setLoading(false)
  }

  useEffect(() => {
    handleGetCurrentUser()
  }, [setCurrentUser])

  // Determine routing based on whether the user is authenticated or not
  // Prompt to "/signin" page if not authenticated
  const Private = ({ children }: { children: React.ReactElement }) => {
    if (!loading) {
      if (isSignedIn) {
        return children
      } else {
        return <Redirect to='/signin' />
      }
    } else {
      return <></>
    }
  }

  return (
    <Router>
      <AuthContext.Provider
        value={{ loading, setLoading, isSignedIn, setIsSignedIn, currentUser, setCurrentUser }}
      >
        <Notifications />
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap['es']}>
          <Switch>
            <Route exact path='/signup' component={SignUp} />
            <Route exact path='/signin' component={SignIn} />
            <CommonLayout>
              <Private>
                <>
                  <Route exact path='/' component={Home} />
                  {/* <RoleBasedRouting exact path="/admin" component={AdminPage} roles={['ROLE_ADMIN']} /> */}
                  {/* <Route exact path='/pp' component={Budgets} />
                  <Route exact path='/ppd' component={BudgetsDashboard} /> */}
                  {grantPermission(ViewRoute.checkins, currentUser?.roleCode) && (
                    <Route exact path='/ch' component={Checkins} />
                  )}
                  {/* <Route exact path='/n' component={Home} />
                  <Route exact path='/c' component={Home} /> */}
                </>
              </Private>
            </CommonLayout>
          </Switch>
        </LocalizationProvider>
      </AuthContext.Provider>
    </Router>
  )
}

export default App
