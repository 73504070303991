import React, { useContext } from 'react'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'

import { AuthContext } from 'App'
import CheckinsCard from './CheckinsCard'

// Display the names and email addresses of authenticated users for now.
const Home: React.FC = () => {
  const { isSignedIn, currentUser } = useContext(AuthContext)

  return (
    <>
      {isSignedIn && currentUser ? (
        <Container>
          <Grid container justifyContent='center'>
            <Grid item xs={12} sm={11} md={10} lg={9} xl={8}>
              <CheckinsCard />
            </Grid>
          </Grid>
        </Container>
      ) : (
        <h1>Not signed in</h1>
      )}
    </>
  )
}

export default Home
