import { AxiosResponse } from 'axios'

import { Employee } from 'interfaces/index'
import client from './client'

interface EmployeesQueryParams {
  employeeId?: number
  active?: boolean
  includes?: any
}

/**
 * Get Employees
 * @param EmployeesQueryParams
 * @returns Checkin[]
 */
export const getEmployees = async ({
  active,
  includes
}: EmployeesQueryParams): Promise<Employee[]> => {
  try {
    const resp: AxiosResponse<{ employees: Employee[] }> = await client.get('/employees', {
      params: {
        'filter[where][active]': active,
        ...(includes ? { includes } : null)
      }
    })
    return resp.data.employees
  } catch (error) {
    throw error
  }
}
