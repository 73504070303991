import { FC, useEffect, useState } from 'react'

import Dialog from '@mui/material/Dialog'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material'
import { DateTimePicker } from '@mui/lab'

import { getPlaces } from 'lib/api/place'
import { CreateCheckin, Place } from 'interfaces'
import { CheckCircleOutlineTwoTone } from '@mui/icons-material'

const CheckinDialog: FC<{
  open: boolean
  employeeId: number
  acceptFx(checkin: CreateCheckin): void
  closeFx: () => void
}> = ({ open, employeeId, acceptFx, closeFx }) => {
  const [places, setPlaces] = useState<Place[]>()
  const [placeId, setPlaceId] = useState<number>()
  const [_in, setIn] = useState<Date | null>(new Date())
  const [_out, setOut] = useState<Date | null>(new Date())
  const [active, setActive] = useState(true)
  useEffect(() => {
    ;(async () => {
      const places = await getPlaces()
      setPlaceId(places[0].id)
      setPlaces(places)
    })()
  }, [])
  return (
    <Dialog open={open}>
      <DialogTitle>Crear check-in</DialogTitle>
      <DialogContent>
        <Grid container justifyContent='space-around' spacing={2}>
          <Grid item sm={6}>
            <ToggleButtonGroup
              color='primary'
              size='medium'
              value={placeId}
              exclusive
              onChange={(event: React.MouseEvent<HTMLElement>, placeId: number) => {
                setPlaceId(placeId)
              }}
              sx={{ marginRight: 3 }}
            >
              {places?.map((place) => (
                <ToggleButton value={place.id} key={place.id}>
                  {place.name}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Grid>
          <Grid item sm={6}>
            <ToggleButton
              value='check'
              color='primary'
              size='medium'
              selected={active}
              onChange={() => {
                setActive(!active)
              }}
            >
              Activo
              <CheckCircleOutlineTwoTone />
            </ToggleButton>
          </Grid>
          <Grid item sm={6}>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              clearable={true}
              label='Ingreso'
              value={_in}
              onChange={(newValue) => {
                setIn(newValue)
              }}
            />
          </Grid>
          <Grid item sm={6}>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              clearable
              label='Egreso'
              value={_out}
              onChange={(newValue) => {
                setOut(newValue)
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            placeId &&
              !!_in &&
              !!_out &&
              acceptFx({
                in: _in,
                out: _out,
                active,
                placeId,
                employeeId
              })
          }}
          disabled={!placeId || !_in || !_out}
        >
          Guardar
        </Button>
        <Button onClick={closeFx}>Cancelar</Button>
      </DialogActions>
    </Dialog>
  )
}

export default CheckinDialog
