import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'

import TextField from '@mui/material/TextField'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Button from '@mui/material/Button'

import { AuthContext } from 'App'
import AlertMessage from 'utils/AlertMessage'
import { signUp } from 'lib/api/auth'
import { SignUpParams } from 'interfaces/index'

// Page for sign-up
const SignUp: React.FC = () => {
  const histroy = useHistory()

  const { setIsSignedIn, setCurrentUser } = useContext(AuthContext)

  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('')
  const [alertMessageOpen, setAlertMessageOpen] = useState<boolean>(false)

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    const params: SignUpParams = {
      name: name,
      email: email,
      password: password,
      passwordConfirmation: passwordConfirmation
    }

    try {
      const res = await signUp(params)
      console.log(res)

      if (res.status === 200) {
        // Let the user log in as soon as the account is created.
        // Normally, we should include an email confirmation, but since this is a sample
        Cookies.set('_access_token', res.headers['access-token'])
        Cookies.set('_client', res.headers['client'])
        Cookies.set('_uid', res.headers['uid'])

        setIsSignedIn(true)
        setCurrentUser(res.data)

        histroy.push('/')

        console.log('Signed in successfully!')
      } else {
        setAlertMessageOpen(true)
      }
    } catch (err) {
      console.log(err)
      setAlertMessageOpen(true)
    }
  }

  return (
    <>
      <form noValidate autoComplete='off'>
        <Card>
          <CardHeader title='Sign Up' />
          <CardContent>
            <TextField
              variant='outlined'
              required
              fullWidth
              label='Name'
              value={name}
              margin='dense'
              onChange={(event) => setName(event.target.value)}
            />
            <TextField
              variant='outlined'
              required
              fullWidth
              label='Email'
              value={email}
              margin='dense'
              onChange={(event) => setEmail(event.target.value)}
            />
            <TextField
              variant='outlined'
              required
              fullWidth
              label='Password'
              type='password'
              value={password}
              margin='dense'
              autoComplete='current-password'
              onChange={(event) => setPassword(event.target.value)}
            />
            <TextField
              variant='outlined'
              required
              fullWidth
              label='Password Confirmation'
              type='password'
              value={passwordConfirmation}
              margin='dense'
              autoComplete='current-password'
              onChange={(event) => setPasswordConfirmation(event.target.value)}
            />
            <Button
              type='submit'
              variant='contained'
              size='large'
              fullWidth
              color='inherit'
              disabled={!name || !email || !password || !passwordConfirmation ? true : false}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </CardContent>
        </Card>
      </form>
      <AlertMessage // Alert if an error occurs
        open={alertMessageOpen}
        setOpen={setAlertMessageOpen}
        severity='error'
        message='Invalid email or password'
      />
    </>
  )
}

export default SignUp
