import { AxiosResponse } from 'axios'

import { Checkin, CreateCheckin } from 'interfaces/index'
import client from './client'

interface CheckinQueryParams {
  periodYear?: number
  periodMonth?: number
  employeeId?: number
  active?: boolean
  maxDays?: number
  includes?: any
}

/**
 * Get Checkins
 * @param CheckinQueryParams
 * @returns Checkin[]
 */
export const getCheckins = async ({
  periodYear,
  periodMonth,
  employeeId,
  active,
  maxDays,
  includes
}: CheckinQueryParams): Promise<Checkin[]> => {
  try {
    const resp: AxiosResponse<{ checkins: Checkin[] }> = await client.get('/checkins', {
      params: {
        ...(active !== undefined ? { 'filter[where][active]': active } : null),
        ...(periodYear ? { 'filter[where][period_year]': periodYear } : null),
        ...(periodMonth ? { 'filter[where][period_month]': periodMonth } : null),
        ...(employeeId ? { 'filter[where][employee_id]': employeeId } : null),
        ...(maxDays ? { 'filter[where][max_days]': maxDays } : null),
        ...(includes ? { includes } : null)
      }
    })
    return resp.data.checkins
  } catch (error) {
    throw error
  }
}

export const createCheckIn = async (checkin: CreateCheckin): Promise<Checkin> => {
  try {
    const resp: AxiosResponse<{ checkin: Checkin }> = await client.post('/checkins', checkin)
    return resp.data.checkin
  } catch (error) {
    throw error
  }
}

interface InLatLong {
  inLatitude?: number
  inLongitude?: number
}

interface CreateCheckinParams extends InLatLong {
  active: boolean
  placeId: number
}

export const doCheckIn = async (checkin: CreateCheckinParams): Promise<Checkin> => {
  try {
    const resp: AxiosResponse<{ checkin: Checkin }> = await client.post(
      '/checkins/check_in',
      checkin
    )
    return resp.data.checkin
  } catch (error) {
    throw error
  }
}

interface OutLatLong {
  outLatitude?: number
  outLongitude?: number
}

interface UpdateCheckinParams extends OutLatLong {
  out?: string
  // active?: boolean
}

export const updateCheckIn = async (
  externalId: string,
  checkin: UpdateCheckinParams | Omit<Checkin, 'externalId'>
): Promise<Checkin> => {
  try {
    const resp: AxiosResponse<{ checkin: Checkin }> = await client.put(
      `/checkins/${externalId}`,
      checkin
    )
    return resp.data.checkin
  } catch (error) {
    throw error
  }
}

export const doCheckOut = async (
  externalId: string,
  checkin: UpdateCheckinParams | Omit<Checkin, 'externalId'>
): Promise<Checkin> => {
  try {
    const resp: AxiosResponse<{ checkin: Checkin }> = await client.put(
      `/checkins/${externalId}/check_out`,
      checkin
    )
    return resp.data.checkin
  } catch (error) {
    throw error
  }
}

export const deleteCheckIn = async (externalId: string): Promise<boolean> => {
  try {
    const resp: AxiosResponse<{ checkin: Checkin }> = await client.delete(`/checkins/${externalId}`)
    return resp.status === 204
  } catch (error) {
    throw error
  }
}
