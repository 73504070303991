import { useState, useEffect, FC } from 'react'
import { useDispatch } from 'react-redux'
import { format } from 'date-fns'

import { styled } from '@mui/material/styles'
import Box from '@mui/system/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

import TimerTwoTone from '@mui/icons-material/TimerTwoTone'
import TimerOffTwoTone from '@mui/icons-material/TimerOffTwoTone'

import { getCheckins, doCheckIn, doCheckOut } from 'lib/api/checkin'
import { getPlaces } from 'lib/api/place'
import { Checkin, Place } from 'interfaces'
// import Map from './Map'
import { pushNotification } from 'store/notifications/reducer'

const MAX_ERRORS_ALLOWED = 3

const CheckoutButton = styled(IconButton)({
  fontSize: 12,
  padding: '0 2px',
  lineHeight: 1.5
})

const CheckinsCard: FC = () => {
  const dispatch = useDispatch()
  const [places, setPlaces] = useState<Place[]>()
  const [placeId, setPlaceId] = useState<number>()
  const [createErrors, setCreateErrors] = useState<number>(0)
  const [updateErrors, setUpdateErrors] = useState<number>(0)
  const [checkins, setCheckins] = useState<Checkin[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    ;(async () => {
      const places = await getPlaces()
      setPlaceId(places[0].id)
      setPlaces(places)
      const checkins = await getCheckins({
        active: true,
        maxDays: 7,
        includes: {
          externalId: true,
          minutes: true,
          in: true,
          out: true,
          place: { name: true }
        }
      })
      setCheckins(checkins)
    })()
  }, [])
  const handleChange = (event: React.MouseEvent<HTMLElement>, placeId: number) => {
    setPlaceId(placeId)
  }
  const checkIn = async (placeId: number, position?: GeolocationPosition) => {
    try {
      const checkin = await doCheckIn({
        active: true,
        placeId,
        ...(position
          ? { inLatitude: position.coords.latitude, inLongitude: position.coords.longitude }
          : null)
      })
      const aux = [...checkins]
      aux.splice(0, 0, checkin)
      setCheckins(aux)
      setCreateErrors(0)
      dispatch(
        pushNotification({
          title: 'Check-in creado',
          message: checkin.in
            ? `Ing: ${format(new Date(checkin.in), 'yyyy/M/d HH:mm:ss')} hs.`
            : '',
          variant: 'success'
        })
      )
    } catch (error: any) {
      dispatch(pushNotification(error))
    } finally {
      setLoading(false)
    }
  }
  const handleDoCheckIn = () => {
    setLoading(true)
    if (placeId) {
      if (createErrors >= MAX_ERRORS_ALLOWED) {
        return checkIn(placeId)
      }
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          await checkIn(placeId, position)
        },
        async (err) => {
          setCreateErrors(createErrors + 1)
          setLoading(false)
          dispatch(
            pushNotification({
              title: `ERROR(${err.code})`,
              message: err.message,
              variant: 'error'
            })
          )
        },
        { timeout: 10000, enableHighAccuracy: true, maximumAge: 0 }
      )
    }
  }
  const checkOut = async (checkin: Checkin, index: number, position?: GeolocationPosition) => {
    try {
      const updatedCheckin = await doCheckOut(checkin.externalId, {
        ...(position
          ? { outLatitude: position.coords.latitude, outLongitude: position.coords.longitude }
          : null)
      })
      dispatch(
        pushNotification({
          title: 'Check-in cerrado',
          message: updatedCheckin.out
            ? `Egr: ${format(new Date(updatedCheckin.out), 'yyyy/M/d HH:mm:ss')} hs.`
            : '',
          variant: 'success'
        })
      )
      setUpdateErrors(0)
      setCheckins((items) => [
        ...items.slice(0, index),
        { ...updatedCheckin, saving: false },
        ...items.slice(index + 1)
      ])
    } catch (error: any) {
      dispatch(pushNotification(error))
      setCheckins((items) => [
        ...items.slice(0, index),
        { ...checkin, saving: false },
        ...items.slice(index + 1)
      ])
    }
  }
  const handleDoCheckOut = (checkin: Checkin, index: number) => {
    setCheckins((items) => [
      ...items.slice(0, index),
      { ...items[index], saving: true },
      ...items.slice(index + 1)
    ])
    if (updateErrors >= MAX_ERRORS_ALLOWED) {
      return checkOut(checkin, index)
    }
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        checkOut(checkin, index, position)
      },
      async (err) => {
        setUpdateErrors(updateErrors + 1)
        dispatch(
          pushNotification({ title: `ERROR(${err.code})`, message: err.message, variant: 'error' })
        )
      },
      { timeout: 10000, enableHighAccuracy: true, maximumAge: 0 }
    )
  }
  return (
    <Card square variant='outlined'>
      <CardHeader
        title={
          <Typography variant='h6' color='primary'>
            Check-In
          </Typography>
        }
        subheader='Últimos 7 días'
      />
      <Divider />
      <CardContent sx={{ height: 300, overflow: 'auto' }}>
        <List dense>
          {checkins.map((checkin, index) => (
            <ListItem
              divider
              disablePadding
              key={checkin.externalId}
              secondaryAction={
                checkin.out ? (
                  <Typography
                    sx={{ display: 'inline', textAlign: 'center' }}
                    component='span'
                    variant='caption'
                    color='text.primary'
                  >
                    <div>{checkin.minutes}</div>
                    <div>min</div>
                  </Typography>
                ) : (
                  <CheckoutButton
                    color='warning'
                    size='small'
                    onClick={() => !checkin.saving && handleDoCheckOut(checkin, index)}
                  >
                    {checkin.saving ? (
                      <CircularProgress color='inherit' size={20} />
                    ) : (
                      <TimerOffTwoTone />
                    )}
                  </CheckoutButton>
                )
              }
            >
              <ListItemAvatar>
                <Typography component='span' variant='caption' color='text.primary'>
                  {checkin.place?.name}
                </Typography>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography component='span' variant='caption'>
                      {checkin.in &&
                        `Ing: ${format(new Date(checkin.in), 'yyyy/M/d HH:mm:ss')} hs.`}
                    </Typography>
                    <Typography component='span' variant='caption'>
                      {checkin.out &&
                        `Egr: ${format(new Date(checkin.out), 'yyyy/M/d HH:mm:ss')} hs.`}
                    </Typography>
                  </Box>
                }
              />
            </ListItem>
          ))}
        </List>
      </CardContent>
      <Divider />
      <CardActions sx={{ justifyContent: 'space-between' }}>
        <ToggleButtonGroup
          color='primary'
          size='medium'
          value={placeId}
          exclusive
          onChange={handleChange}
          sx={{ marginRight: 3 }}
        >
          {places?.map((place) => (
            <ToggleButton value={place.id} key={place.id} sx={{ fontSize: '0.6rem' }}>
              {place.name}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        {checkins.length === 0 || (checkins[0] && checkins[0].out) ? (
          <Button
            sx={{ fontSize: '0.85rem' }}
            startIcon={loading ? <CircularProgress color='inherit' size={20} /> : <TimerTwoTone />}
            color='warning'
            variant='outlined'
            onClick={() => !loading && handleDoCheckIn()}
          >
            Check-in!
          </Button>
        ) : (
          <Typography color='warning' fontSize='0.9rem' border='1px solid' padding='2px'>
            Cierre su último Checkin antes de crear uno nuevo.
          </Typography>
        )}
      </CardActions>
      {/* <CardContent sx={{ height: 400 }}>
        <Map/>
      </CardContent> */}
    </Card>
  )
}

export default CheckinsCard
