import { AxiosResponse } from 'axios'
import Cookies from 'js-cookie'

import client from './client'
import { SignUpParams, SignInParams, User } from 'interfaces/index'

// Sign up (create a new account)
export const signUp = (params: SignUpParams): Promise<AxiosResponse<User>> => {
  return client.post('auth', params)
}

/**
 * Sign in (login)
 * @param params SignInParams
 * @returns User
 */
export const signIn = (params: SignInParams): Promise<AxiosResponse<{ data: User }>> => {
  return client.post('/auth/sign_in', params)
}

/**
 * Sign out (logout)
 */
export const signOut = (): Promise<AxiosResponse> => {
  const headers: { headers: { 'access-token'?: string; client?: string; uid?: string } } = {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid')
    }
  }
  return client.delete('/auth/sign_out', headers)
}

/**
 * Get an authenticated user
 * @returns AxiosResponse<{ data: User; success: boolean }>
 */
export const getCurrentUser = ():
  | Promise<AxiosResponse<{ data: User; success: boolean }>>
  | undefined => {
  if (!Cookies.get('_access_token') || !Cookies.get('_client') || !Cookies.get('_uid')) {
    return
  }
  const headers: { headers: { 'access-token'?: string; client?: string; uid?: string } } = {
    headers: {
      'access-token': Cookies.get('_access_token'),
      client: Cookies.get('_client'),
      uid: Cookies.get('_uid')
    }
  }
  return client.get('/auth/validate_token', headers)
}
