import { forwardRef, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar, SnackbarContent } from 'notistack'

import Card from '@mui/material/Card'
import { CardActions, CardHeader, IconButton, Paper, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'

import { RootState } from 'store/store'
import { removeNotification } from 'store/notifications/reducer'

const SnackMessage = forwardRef<
  HTMLDivElement,
  { id: string | number; message: string | React.ReactNode; title?: string | React.ReactNode }
>((props, ref) => {
  const { closeSnackbar } = useSnackbar()

  const handleDismiss = useCallback(() => {
    closeSnackbar(props.id)
  }, [props.id, closeSnackbar])

  return (
    <SnackbarContent ref={ref} style={{ padding: 0 }}>
      <Card
        elevation={0}
        component={Paper}
        style={{
          color: 'white',
          background: 'none',
          border: 'none',
          width: '100%'
        }}
      >
        {props.title && (
          <CardHeader
            sx={{ padding: 0 }}
            title={<Typography variant='h6'>{props.title}</Typography>}
          />
        )}
        <CardActions sx={{ padding: 0, justifyContent: 'space-between' }}>
          <Typography variant='caption'>{props.message}</Typography>
          <IconButton onClick={handleDismiss}>
            <Close color='inherit' />
          </IconButton>
        </CardActions>
      </Card>
    </SnackbarContent>
  )
})

let displayed: string[] = []

const Notifications = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const notifications = useSelector((state: RootState) => state.notifications)
  const dispatch = useDispatch()

  const storeDisplayed = (id: string) => {
    displayed = [...displayed, id]
  }

  const removeDisplayed = (id: string | number) => {
    displayed = [...displayed.filter((key) => id !== key)]
  }

  useEffect(() => {
    notifications.forEach(({ key, title, message, options, dismissed = false }) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key)
        return
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) return

      // display snackbar using notistack
      enqueueSnackbar(<SnackMessage id={key} message={message} title={title} />, {
        ...options,
        key,
        onClose: (event, reason, myKey) => {
          if (options?.onClose) {
            options.onClose(event, reason, myKey)
          }
        },
        onExited: (event, myKey) => {
          // remove this snackbar from redux store
          dispatch(removeNotification(myKey))
          removeDisplayed(myKey)
        }
      })

      // keep track of snackbars that we've displayed
      storeDisplayed(key)
    })
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch])
  return null
}

export default Notifications
