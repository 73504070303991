import { RoleCode } from 'interfaces/index'

export enum ViewRoute {
  checkins = 'checkins'
}

export const grantPermission = (view: ViewRoute, requestedRole?: RoleCode) => {
  if (!requestedRole) {
    return false
  }
  switch (view) {
    case ViewRoute.checkins:
      return [
        RoleCode.admin,
        RoleCode.manager,
        RoleCode.coordinator,
        RoleCode.areaChief,
        RoleCode.humanResourceManager
      ].includes(requestedRole)
    default:
      return false
  }
}
