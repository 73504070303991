import { OptionsObject } from 'notistack'

// Sign up
export interface SignUpParams {
  name: string
  email: string
  password: string
  passwordConfirmation: string
}

// Sign in
export interface SignInParams {
  email: string
  password: string
}

export interface Employee {
  id: number
  personnelFile: number
  cuil: string
  fulltime: string
  acronym: string
  name: string
  lastname: string
  image?: string | null
  createdAt: string
  updatedAt: string
  userId: number
  employeeHourTypeId: number
  employeeCategoryId: number
  employeeAreaId: number
}

export enum RoleCode {
  admin = 'admin',
  administrativeAssistant = 'administrativeassistant',
  areaChief = 'areachief',
  auxiliaryCoordinator = 'auxiliarycoordinator',
  coordinator = 'coordinator',
  employee = 'employee',
  humanResourceManager = 'humanresourcemanager',
  manager = 'manager',
  projectLeader = 'projectleader'
}

interface BaseUser {
  id: number
  email: string
  uid: string
  provider: string
  active: boolean
}

// User
export interface User extends BaseUser {
  expiresAt?: string
  firstSignIn?: boolean
  role?: string
  // ###
  employeeId?: number
  roleName?: string
  roleCode?: RoleCode
  employee?: Employee
}

export interface PaginationMeta {
  count: number
  currentPage: number
  nextPage?: number
  prevPage?: number
  totalCount: number
  totalPages: number
}

interface Customer {
  id: number
  name: string
  active: boolean
  created_at: string
  updated_at: string
}
export interface Budget {
  budgetPerWorks: any[]
  budgetPurchaseOrder: object
  customer: Customer
  description: string
  employee: Employee
  id: number
  name: string
  refId: number
  state: string
}

export interface Place {
  id: number
  name: string
  active: boolean
}

export interface CreateCheckin {
  employeeId: number
  placeId: number
  active: boolean
  in: Date
  out: Date
}

export interface Checkin {
  placeId?: number
  in?: Date
  out?: Date
  active?: boolean
  externalId: string
  place?: Place
  minutes?: number
  periodYear?: number
  periodMonth?: number
  inLatitude?: number
  inLongitude?: number
  outLatitude?: number
  outLongitude?: number
  saving?: boolean
}

export interface NotificationState {
  key: string
  message: string
  title?: string
  options?: OptionsObject
  dismissed?: boolean
  autoHideDuration?: number
}
