import { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import format from 'date-fns/format'
import addYears from 'date-fns/addYears'

import {
  DataGrid,
  GridColDef,
  GridSelectionModel,
  GridToolbar,
  esES,
  GridEditRowsModel
} from '@mui/x-data-grid'
import { Box } from '@mui/system'
import { Autocomplete, Button, CircularProgress, Grid, Stack, TextField } from '@mui/material'

import DatePicker from '@mui/lab/DatePicker'

import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone'
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone'

import { Checkin, CreateCheckin, Employee } from 'interfaces'
import { createCheckIn, deleteCheckIn, getCheckins, updateCheckIn } from 'lib/api/checkin'
import { getEmployees } from 'lib/api/employee'
import { pushNotification } from 'store/notifications/reducer'

import Map from './Map'
import CheckinDialog from './CheckinDialog'

const columns: GridColDef[] = [
  { field: 'externalId', headerName: 'ID', width: 90, hide: true },
  {
    field: 'in',
    headerName: 'In',
    type: 'dateTime',
    width: 230,
    editable: true,
    valueGetter: ({ value }) => value && new Date(value),
    valueFormatter: ({ value }) => {
      if (value && value instanceof Date) {
        return `${format(value, 'yyyy/M/d HH:mm:ss')} hs.`
      }
      return value
    }
  },
  {
    field: 'out',
    headerName: 'Out',
    type: 'dateTime',
    width: 230,
    editable: true,
    valueGetter: ({ value }) => value && new Date(value),
    valueFormatter: ({ value }) => {
      if (value && value instanceof Date) {
        return `${format(value, 'yyyy/M/d HH:mm:ss')} hs.`
      }
      return value
    }
  },
  {
    field: 'minutes',
    headerName: 'Minutos',
    width: 90,
    type: 'string'
  },
  {
    field: 'place',
    headerName: 'Lugar',
    width: 90,
    type: 'string',
    valueGetter: ({ value }) => value && value.name
  },
  { field: 'active', headerName: 'Activo', width: 90, type: 'boolean' }
]

const Checkins: FC = () => {
  const dispatch = useDispatch()
  const [openAutoComplete, setOpenAutoComplete] = useState(false)
  const [employees, setEmployees] = useState<readonly Employee[]>([])
  const [checkins, setCheckins] = useState<Checkin[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedRows, setSelectedRows] = useState<GridSelectionModel>([])
  const [singleSelection, setSingleSelection] = useState<boolean>(false)
  const [employeeId, setEmployeeId] = useState<number>()
  const [loadingEmployees, setLoadingEmployees] = useState<boolean>(true)
  const [yearAndMonth, setYearAndMonth] = useState<Date>(new Date())

  const [dataToSave, setDataToSave] = useState<Checkin[]>([])
  const [editRowsModel, setEditRowsModel] = useState({})
  const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false)

  const handleEditRowsModelChange = useCallback((model: GridEditRowsModel) => {
    if (
      model && // 👈 null and undefined check
      Object.keys(model).length === 0 &&
      Object.getPrototypeOf(model) === Object.prototype
    ) {
    } else {
      const externalId = Object.keys(model)[0]
      const property = Object.keys(model[externalId])[0]
      const data = { [property]: model[externalId][property].value }
      setDataToSave((items) => {
        const index = items.findIndex((d) => d.externalId === externalId)
        const _in = data['in'] && data['in'] instanceof Date ? { in: data['in'] } : null
        const _out = data['out'] && data['out'] instanceof Date ? { out: data['out'] } : null
        if (index >= 0) {
          return [
            ...items.slice(0, index),
            {
              ...items[index],
              ..._in,
              ..._out
            },
            ...items.slice(index + 1)
          ]
        }
        return [
          ...items,
          {
            externalId,
            ..._in,
            ..._out
          }
        ]
      })
    }
    setEditRowsModel(model)
  }, [])
  const handleCreateCheckin = async (checkin: CreateCheckin) => {
    try {
      if (typeof employeeId === 'number') {
        const checkinCreated = await createCheckIn(checkin)
        const aux = [...checkins]
        aux.splice(0, 0, checkinCreated)
        setCheckins(aux)
        dispatch(
          pushNotification({
            title: 'Check-in creado',
            message: `Ing: ${format(
              new Date(checkin.in),
              'yyyy/M/d HH:mm:ss'
            )} hs. -- Egr: ${format(new Date(checkin.out), 'yyyy/M/d HH:mm:ss')} hs.`,
            variant: 'success'
          })
        )
      }
      setOpenCreateDialog(false)
    } catch (error: any) {
      dispatch(pushNotification(error))
    } finally {
      setLoading(false)
    }
  }
  const handleUpdateCheckins = async () => {
    const res = await Promise.all(
      dataToSave.map(async (data) => {
        const { externalId, ...rest } = data
        await updateCheckIn(externalId, rest)
        return externalId
      })
    )
    const aux = dataToSave.filter((a) => !res.includes(a.externalId))
    dispatch(
      pushNotification({
        title: 'Check-ins actualizados',
        message: `${dataToSave.length} actualizado/s`,
        variant: 'success'
      })
    )
    setDataToSave(aux)
  }
  const handleRemoveCheckin = async () => {
    try {
      const idToRemove = selectedRows[0].toString()
      await deleteCheckIn(idToRemove)
      const index = checkins.findIndex((d) => d.externalId === idToRemove)
      if (index >= 0) {
        const aux = [...checkins]
        const [deletedCheckin] = aux.splice(index, 1)
        setCheckins(aux)
        dispatch(
          pushNotification({
            title: 'Check-in eliminado',
            message: `${
              deletedCheckin.in
                ? `Ing: ${format(new Date(deletedCheckin.in), 'yyyy/M/d HH:mm:ss')} hs.`
                : ''
            } ${
              deletedCheckin.out
                ? `Egr: ${format(new Date(deletedCheckin.out), 'yyyy/M/d HH:mm:ss')} hs.`
                : ''
            }`,
            variant: 'info'
          })
        )
      }
    } catch (error: any) {
      dispatch(pushNotification(error))
    }
  }
  useEffect(() => {
    let active = true
    ;(async () => {
      const employees = await getEmployees({
        includes: { id: true, name: true, lastname: true, personnelFile: true }
      })
      setEmployees([...employees])
      if (!active) {
        return
      }
      setLoadingEmployees(false)
    })()
    return () => {
      active = false
    }
  }, [])
  useEffect(() => {
    let active = true
    ;(async () => {
      if (employeeId && yearAndMonth) {
        setLoading(true)
        const checkins = await getCheckins({
          periodMonth: yearAndMonth.getMonth() + 1,
          periodYear: yearAndMonth.getFullYear(),
          employeeId,
          includes: {
            externalId: true,
            minutes: true,
            active: true,
            employee: { name: true, lastname: true },
            place: { name: true },
            in: true,
            out: true,
            inLatitude: true,
            inLongitude: true,
            outLatitude: true,
            outLongitude: true
          }
        })
        if (!active) {
          return
        }
        setCheckins(checkins)
        setLoading(false)
      }
    })()
    return () => {
      active = false
    }
  }, [employeeId, yearAndMonth])
  useEffect(() => {
    setSingleSelection(selectedRows.length === 1)
  }, [selectedRows])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 3.5rem)' }}>
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
        sx={{ padding: 1 }}
      >
        <Grid container>
          <Grid item xs={12} md={3}>
            <DatePicker
              views={['year', 'month']}
              label='Año y Mes'
              // mask={esLocale}
              minDate={new Date(2016, 12, 1)}
              maxDate={addYears(new Date(), 1)}
              value={yearAndMonth}
              onChange={(newValue) => {
                newValue && setYearAndMonth(newValue)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size='small'
                  sx={{ sm: { width: '50%' } }}
                  helperText={null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Autocomplete
              size='small'
              sx={{ sm: { width: '50%' } }}
              open={openAutoComplete}
              onOpen={() => {
                setOpenAutoComplete(true)
              }}
              onClose={() => {
                setOpenAutoComplete(false)
              }}
              isOptionEqualToValue={(option, value) =>
                option.id === value.id || option.personnelFile === value.personnelFile
              }
              onChange={(_, value) => {
                setEmployeeId(value?.id)
              }}
              getOptionLabel={(option) =>
                `${option.personnelFile} - ${option.name} ${option.lastname}`
              }
              options={employees}
              loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Empleado'
                  size='small'
                  sx={{ fontSize: '0.2rem' }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color='inherit' size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={1}>
              <Button
                variant='outlined'
                size='small'
                disabled={dataToSave.length === 0}
                startIcon={<SaveTwoToneIcon />}
                onClick={handleUpdateCheckins}
              >
                Guardar cambios
              </Button>
              <Button
                variant='outlined'
                size='small'
                disabled={!employeeId}
                startIcon={<AddBoxTwoToneIcon />}
                onClick={() => setOpenCreateDialog(true)}
              >
                Crear check-in
              </Button>
              <Button
                variant='outlined'
                size='small'
                disabled={!singleSelection}
                startIcon={<DeleteTwoTone />}
                onClick={handleRemoveCheckin}
              >
                Remover
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      {/* <Grid container flexDirection='row' height='100%' sx={{flexDirection: 'column'}}> */}
      <Grid container sx={{ height: '100%' }}>
        <Grid item xs={12} md={8} lg={7} xl={6}>
          <DataGrid
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            rows={checkins}
            columns={columns}
            getRowId={(row) => row.externalId}
            editRowsModel={editRowsModel}
            onEditRowsModelChange={handleEditRowsModelChange}
            loading={loadingEmployees}
            checkboxSelection={true}
            onSelectionModelChange={(newSelectionModel) => {
              setSelectedRows(newSelectionModel)
            }}
            selectionModel={selectedRows}
            disableSelectionOnClick
            density='compact'
            components={{
              Toolbar: GridToolbar
            }}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={5} xl={6}>
          <Map checkin={checkins.find((checkin) => checkin.externalId === selectedRows[0])} />
        </Grid>
      </Grid>
      {typeof employeeId === 'number' && (
        <CheckinDialog
          open={openCreateDialog}
          employeeId={employeeId}
          acceptFx={(checkin) => handleCreateCheckin(checkin)}
          closeFx={() => setOpenCreateDialog(false)}
        />
      )}
    </div>
  )
}

export default Checkins
