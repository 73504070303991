import { AxiosResponse } from 'axios'

import { Place } from 'interfaces/index'
import client from './client'

/**
 * Get places
 * @returns Place[]
 */
export const getPlaces = async (): Promise<Place[]> => {
  try {
    const resp: AxiosResponse<{ places: Place[] }> = await client.get('/places')
    return resp.data.places
  } catch (error) {
    throw error
  }
}
