import React, { FC, useContext, useEffect, useState } from 'react'
import { useHistory, Link as RouterLink } from 'react-router-dom'
import { format } from 'date-fns'
import Cookies from 'js-cookie'

import { styled } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/system/Box'
// import Collapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Slide from '@mui/material/Slide'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import useScrollTrigger from '@mui/material/useScrollTrigger'
// Icons
import AccountCircle from '@mui/icons-material/AccountCircle'
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone'
// import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone'
// import EditNotificationsTwoToneIcon from '@mui/icons-material/EditNotificationsTwoTone'
// import EngineeringTwoToneIcon from '@mui/icons-material/EngineeringTwoTone'
// import ExpandLess from '@mui/icons-material/ExpandLess'
// import ExpandMore from '@mui/icons-material/ExpandMore'
import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone'
import Logout from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/Menu'
// import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
// import SettingsApplicationsTwoToneIcon from '@mui/icons-material/SettingsApplicationsTwoTone'
import TimerTwoTone from '@mui/icons-material/TimerTwoTone'

import { signOut } from 'lib/api/auth'
import { AuthContext } from 'App'
import { User } from 'interfaces'
import { grantPermission, ViewRoute } from 'utils/permission'

const SIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2)
}))

const getNow = () => {
  const date = new Date()
  return { s: `${format(date, 'yyyy/M/d HH:mm:ss')}hs.`, d: date }
}

const Header: FC = () => {
  const { setIsSignedIn, isSignedIn, currentUser } = useContext(AuthContext)
  const histroy = useHistory()
  const [openDrawer, setOpenDrawer] = useState(false)

  // const [open, setOpen] = useState(true)

  // const handleClick = () => {
  //   setOpen((prevOpen) => !prevOpen)
  // }

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }
    setOpenDrawer(open)
  }
  const drawerMenuList = () => (
    <Box
      sx={{ width: 'auto' }}
      // onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      role='presentation'
    >
      <List
        dense
        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
        component='nav'
        aria-labelledby='nested-list-subheader'
        subheader={
          <ListSubheader component={ListItem} id='nested-list-subheader'>
            <ListItemText primary='Home' />
            <IconButton onClick={toggleDrawer(false)}>
              <CancelTwoToneIcon />
            </IconButton>
          </ListSubheader>
        }
      >
        {/* <Divider />
        <ListSubheader component={ListItem} inset>
          <ListItemText primary='Presupuestos' />
          <ListItemIcon>
            <MonetizationOnIcon fontSize='small' />
          </ListItemIcon>
        </ListSubheader>
        <ListItemButton component={RouterLink as any} to='/pp'>
          <ListItemIcon>
            <ListAltTwoToneIcon />
          </ListItemIcon>
          <ListItemText primary='Listado' />
        </ListItemButton>
        <ListItemButton component={RouterLink as any} to='/ppd'>
          <ListItemIcon>
            <DashboardTwoToneIcon />
          </ListItemIcon>
          <ListItemText primary='Dashboard' />
        </ListItemButton> */}
        {grantPermission(ViewRoute.checkins, currentUser?.roleCode) && (
          <>
            <Divider />
            <ListSubheader component={ListItem} inset>
              <ListItemText primary='Check-ins' />
              <ListItemIcon>
                <TimerTwoTone fontSize='small' />
              </ListItemIcon>
            </ListSubheader>
            <ListItemButton component={RouterLink as any} to='/ch'>
              <ListItemIcon>
                <ListAltTwoToneIcon />
              </ListItemIcon>
              <ListItemText primary='Listado' />
            </ListItemButton>
          </>
        )}
      </List>
      {/* <Divider />
      <List>
        <ListItemButton onClick={handleClick}>
          <ListItemIcon>
            <SettingsApplicationsTwoToneIcon />
          </ListItemIcon>
          <ListItemText primary='Configuraciones' />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            <ListItemButton sx={{ pl: 3 }} component={RouterLink as any} to='/n'>
              <ListItemIcon>
                <EngineeringTwoToneIcon />
              </ListItemIcon>
              <ListItemText primary='Notificaciones' />
            </ListItemButton>
            <ListItemButton sx={{ pl: 3 }} component={RouterLink as any} to='/c'>
              <ListItemIcon>
                <EditNotificationsTwoToneIcon />
              </ListItemIcon>
              <ListItemText primary='Cargos de clientes' />
            </ListItemButton>
          </List>
        </Collapse>
      </List> */}
    </Box>
  )
  const handleSignOut = async (e: React.MouseEvent<HTMLElement>) => {
    try {
      const res = await signOut()
      if (res.status === 200) {
        // Delete each cookie when you sign out
        Cookies.remove('_access_token')
        Cookies.remove('_token_type')
        Cookies.remove('_client')
        Cookies.remove('_uid')
        setIsSignedIn(false)
        histroy.push('/signin')
      } else {
        console.log('Failed in sign out')
      }
    } catch (err) {
      console.log(err)
    }
  }

  const AuthButtons: FC<{ user: User }> = ({ user }) => {
    const [now, setNow] = useState<{ s: string; d: Date }>(getNow())
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
      setAnchorEl(null)
    }
    useEffect(() => {
      const int = setInterval(() => {
        setNow(getNow())
      }, 3000)
      return () => {
        clearInterval(int)
      }
    }, [])
    return (
      <Box display='flex' alignItems='center'>
        <Box display='flex' alignItems='end' flexDirection='column'>
          <Typography variant='subtitle2'>{user.email}</Typography>
          <Typography variant='caption'>
            {now?.s} - {user.role || user.roleName}
          </Typography>
        </Box>
        <IconButton
          size='large'
          aria-label='account of current user'
          aria-controls='menu-appbar'
          aria-haspopup='true'
          onClick={handleMenu}
          color='inherit'
        >
          <AccountCircle />
        </IconButton>
        <Menu
          id='menu-appbar'
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
          <MenuItem onClick={handleClose}>My account</MenuItem> */}
          <MenuItem onClick={handleSignOut}>
            <ListItemIcon>
              <Logout fontSize='small' />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Box>
    )
  }

  const HideOnScroll = ({ children }: { children: React.ReactElement }) => {
    const trigger = useScrollTrigger({
      target: undefined
    })
    return (
      <Slide appear={false} direction='down' in={!trigger}>
        {children}
      </Slide>
    )
  }
  return (
    <>
      <HideOnScroll>
        <AppBar>
          <Toolbar variant='dense'>
            <SIconButton edge='start' color='inherit' onClick={toggleDrawer(true)}>
              <MenuIcon />
            </SIconButton>
            <div style={{ flexGrow: 1 }}>
              <Typography
                component={RouterLink}
                to='/'
                variant='body2'
                sx={{
                  textDecoration: 'none',
                  color: 'inherit',
                  marginTop: 10
                }}
              >
                CIS
                <div style={{ fontSize: 'small', fontWeight: 300 }}>{`${
                  process.env.NODE_ENV === 'production' ? '' : `${process.env.NODE_ENV} - `
                }${process.env.REACT_APP_VERSION}`}</div>
              </Typography>
            </div>
            {isSignedIn && currentUser ? <AuthButtons user={currentUser} /> : null}
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Drawer anchor='left' open={openDrawer} onClose={toggleDrawer(false)}>
        {drawerMenuList()}
      </Drawer>
    </>
  )
}

export default Header
