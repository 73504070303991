import { useState, useContext, FC } from 'react'
import { useHistory } from 'react-router-dom'

import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import CssBaseline from '@mui/material/CssBaseline'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import { AuthContext } from 'App'
import AlertMessage from 'utils/AlertMessage'
import { signIn } from 'lib/api/auth'
import { RoleCode, SignInParams } from 'interfaces/index'

function Copyright(props: any) {
  return (
    <Typography variant='body2' color='text.secondary' align='center' {...props}>
      {`CIS Ingeniería S.R.L. - Copyright © ${new Date().getFullYear()}`}
    </Typography>
  )
}

// Page for sign-in
const SignIn: FC = () => {
  const history = useHistory()

  const { setIsSignedIn, setCurrentUser } = useContext(AuthContext)

  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [alertMessageOpen, setAlertMessageOpen] = useState<boolean>(false)

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    const params: SignInParams = {
      email: email,
      password: password
    }

    try {
      const res = await signIn(params)
      if (res.status === 200) {
        setIsSignedIn(true)
        /***
         * This is a hack in order to assign RoleCode.
         * TODO: Override API serializer when doing /auth/sign_in
         */
        if (!res.data.data.roleCode) {
          const aux: RoleCode = res.data.data.role?.slice(4).toLowerCase() as RoleCode
          res.data.data.roleCode = aux
        }
        setCurrentUser(res.data.data)
        history.push('/')
      } else {
        setAlertMessageOpen(true)
      }
    } catch (err) {
      console.log(err)
      setAlertMessageOpen(true)
    }
  }
  return (
    <>
      <Grid container component='main' sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${process.env.PUBLIC_URL + '/img/background.jpg'})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>{/* <LockOutlinedIcon /> */}</Avatar>
            <Typography component='h1' variant='h5'>
              Sign in
            </Typography>
            <Box component='form' noValidate sx={{ mt: 1 }}>
              <TextField
                margin='normal'
                required
                fullWidth
                label='Email'
                name='email'
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                autoComplete='email'
                autoFocus
              />
              <TextField
                margin='normal'
                required
                fullWidth
                name='password'
                label='Password'
                type='password'
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                autoComplete='current-password'
              />
              <FormControlLabel
                control={<Checkbox value='remember' color='primary' />}
                label='Recordarme'
              />
              <Button
                type='submit'
                onClick={handleSubmit}
                size='large'
                fullWidth
                variant='contained'
                disabled={!email || !password ? true : false} // If there is a blank space, the button cannot be pressed.
                sx={{ mt: 3, mb: 2 }}
              >
                Ingresar
              </Button>
              {/* <Grid container>
                <Grid item xs>
                  <Link href='#' variant='body2'>
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Box textAlign='center'>
                    <Typography variant='body2'>
                      No tienes una cuenta? &nbsp;
                      <Link to='/signup'>Crear una ahora!</Link>
                    </Typography>
                  </Box>
                </Grid>
              </Grid> */}
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <AlertMessage // Alert when an error occurs.
        open={alertMessageOpen}
        setOpen={setAlertMessageOpen}
        severity='error'
        message='Password o Email inválido'
      />
    </>
  )
}

export default SignIn
