import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { SnackbarProvider } from 'notistack'

import { createTheme, ThemeProvider } from '@mui/material/styles'
// https://mui.com/components/about-the-lab/#typescript
import type {} from '@mui/lab/themeAugmentation'

import { store } from './store/store'
import { Provider } from 'react-redux'

import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

const theme = createTheme()

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <App />
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
