import applyCaseMiddleware from 'axios-case-converter'
import axios, { AxiosError, AxiosResponse } from 'axios'
import Cookies from 'js-cookie'

// applyCaseMiddleware:
// Convert the value of the response received by axios from snake case to camel case
// or a library that converts the value of a request to be sent from camel case to snake case

// add an option to ignore the application of the kebab case for headers
const options = {
  ignoreHeaders: true
}

const baseURL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:3000'

const client = applyCaseMiddleware(
  axios.create({
    baseURL
  }),
  options
)

const getHeaders = () => {
  const expiry = Cookies.get('_expiry')
  const accessToken = Cookies.get('_access_token')
  const tokenType = Cookies.get('_token_type')
  const client = Cookies.get('_client')
  const uid = Cookies.get('_uid')
  return {
    expiry,
    'access-token': accessToken,
    'token-type': tokenType,
    client,
    uid
  }
}

const setHeaders = (response: AxiosResponse) => {
  !!response.headers['expiry'] && Cookies.set('_expiry', response.headers['expiry'])
  !!response.headers['access-token'] &&
    Cookies.set('_access_token', response.headers['access-token'])
  !!response.headers['token-type'] && Cookies.set('_token_type', response.headers['token-type'])
  !!response.headers['client'] && Cookies.set('_client', response.headers['client'])
  !!response.headers['uid'] && Cookies.set('_uid', response.headers['uid'])
}

client.interceptors.request.use(
  (param) => {
    if (param?.url?.includes('/auth')) {
      return param
    }
    return {
      ...param,
      headers: {
        ...param.headers,
        ...getHeaders()
      }
    }
  },
  (error: AxiosError) => {
    // handling error
    throw error
  }
)

client.interceptors.response.use(
  (value: AxiosResponse) => {
    setHeaders(value)
    return value
  },
  (error: AxiosError) => {
    console.log(error)
    throw error
  }
)

export default client
