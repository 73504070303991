import React from 'react'

import Container from '@mui/material/Container'

import Header from 'layouts/Header'

interface CommonLayoutProps {
  children: React.ReactElement
}

// Layout to be the same for all pages
const CommonLayout = ({ children }: CommonLayoutProps) => {
  return (
    <>
      <header>
        <Header />
      </header>
      <main>
        <Container
          sx={{ mt: { xs: '5.5rem', sm: '3.5rem' }, mx: 0 }}
          disableGutters
          maxWidth={false}
        >
          {children}
        </Container>
      </main>
    </>
  )
}

export default CommonLayout
